<!--
#########################################################################################
######    Vista SVC00001  :  MENÚ PRINCIPAL                                        ######
#########################################################################################
-->
<template>
  <div>
      <!-- Top Navigation (Genérico) -->
      <TNC10000></TNC10000>

        <div>

            <div class="mt-3">
            <h3 class="text-warning mt-0">Opciones Frecuentes</h3>
            </div>

            <div class="d-flex row">

            <CRD10000>
                    <template #asunto><router-link to="/00001">Inicio</router-link></template>
                    <template #titulo>Página Inicial</template>
                    <template #cuerpo>Ubicación inicial, desde la que es posible alcanzar a la mayoría de las opciones disponibles</template>
                    <template #leyenda>(Ctrl+Shift+<b>0</b>)</template>
            </CRD10000>

            <CRD10000>
                    <template #asunto><router-link to="/11100">Editar Mapa</router-link></template>
                    <template #titulo>Edición del Mapa</template>
                    <template #cuerpo>Acceso a las herramientas que permiten ...<ul class="text-left"><li>* Creación de Pasillos y Calles</li><li>* Indicar Gamas x Pasillos, etc.</li></ul></template>
                    <template #leyenda>(Ctrl+Shift+<b>1</b>)</template>
            </CRD10000>

            <CRD10000>
                    <template #asunto><router-link to="/20000">Entradas</router-link></template>
                    <template #titulo>Entradas en Almacén</template>
                    <template #cuerpo>Monitorización y Seguimiento de las <code>Compras</code> confirmadas a partir de los <b>Pedidos de Compra</b> asociados</template>
                    <template #leyenda>(Ctrl+Shift+<b>2</b>)</template>
            </CRD10000>

            <CRD10000>
                    <template #asunto><router-link to="/30000">Salidas</router-link></template>
                    <template #titulo>Salidas de Almacén</template>
                    <template #cuerpo>Control de las Tareas relacionadas con las <code>Ventas</code>, y otros procesos que originan <b>Salidas de Mercancía</b> de el/los almacenes gestionados</template>
                    <template #leyenda>(Ctrl+Shift+<b>3</b>)</template>
            </CRD10000>

            <CRD10000>
                    <template #asunto><router-link to="/31200">Preparación Cargas</router-link></template>
                    <template #titulo>Cargas x Palé</template>
                    <template #cuerpo>Preparación de los <code>Artículos</code> a cargar en cada <code>Palé</code>, para facilitar la tarea del Cargador</template>
                    <template #leyenda>(Ctrl+Shift+<b>C</b>)</template>
            </CRD10000>

            <CRD10000>
                    <template #asunto><router-link to="/40000">Recuentos</router-link></template>
                    <template #titulo>Recuento de Stock</template>
                    <template #cuerpo>Herramientas para el seguimiento y control del <code>Stock</code> registrado en <nobr><i>iX-Distribución</i></nobr></template>
                    <template #leyenda>(Ctrl+Shift+<b>4</b>)</template>
            </CRD10000>

            <CRD10000>
                    <template #asunto><router-link to="/51100">Consulta de Movimientos</router-link></template>
                    <template #titulo>Consultar Entradas y Salidas</template>
                    <template #cuerpo>Herramientas para determinar las <code>Entradas</code> y <code>Salidas</code> realizadas a lo largo de un período de tiempo</template>
                    <template #leyenda>(Ctrl+Shift+<b>8</b>)</template>
            </CRD10000>

            <CRD10000>
                    <template #asunto><router-link to="/51200">Actividad del Almacén</router-link></template>
                    <template #titulo>Consulta de la Actividad de los Almaceneros</template>
                    <template #cuerpo>Herramientas para determinar los <code>Bultos</code> y las <code>Horas de Actividad</code> en un tramo de tiempo</template>
                    <template #leyenda>(Ctrl+Shift+<b>9</b>)</template>
            </CRD10000>

            </div>

            <div class="mt-5"><br/><br/><br/></div>

        </div>

      <BNC10000></BNC10000>

  </div>
</template>

<script>
import igs_mixin    from '@/igs-mixins/igs_mixin'
import TNC10000     from '@/igs-navegacion/TNC10000'
import BNC10000     from '@/igs-navegacion/BNC10000'
import CRD10000     from '@/igs-navegacion/CRD10000'


export default {
  mixins:       [ igs_mixin ],
  components :  { TNC10000, BNC10000, CRD10000 },

  //----------------------------
  //-- Datos Locales
  //----------------------------
  data() {
    return {
       //------------------------------------------------------------------------
       //-- Variables Locales
       //------------------------------------------------------------------------
        sTitulo : 'Hola SVC00001'
    }
              

  },

  //----------------------------
  //-- Métodos Locales
  //----------------------------
  methods: {
    //-- Acudir a la Opción Seleccionada
    lp99OpcionMenu(camino) {
      //console.log('lp99OpcionMenu : '+camino);
      this.$router.push(camino);
    }
  },

  //----------------------------
  //-- Datos Calculados
  //----------------------------
  computed: {

  },

  //----------------------------
  //-- Al cargar esta página
  //----------------------------
  mounted() {
      //-- Actualizar Módulo "Vivo"
      this.$store.commit('p10StrModuleName', 'SVC00001');

      //-- Actualizar BreadCrumb 
      this.$store.commit('p10StrMenuBC', [
        {
          text: "0. Inicio",
          active: true
        }
      ]);
  }

}
</script>

<style lang="scss">
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
/*
li {
  display: inline-block;
  margin: 0 10px;
}
*/
a {
  color: (--primary);
}
</style>
